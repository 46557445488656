// google fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");

// import pages
@import url("./pages/home.scss");
@import url("./pages/contact.scss");
@import url("./pages/service.scss");
@import url("./pages/blog.scss");
@import url("./pages/faq.scss");
@import url("./pages/algo-lib.scss");

.heading_one {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  color: #4b9adf;
}

.heading-two {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  color: #4b9adf;
}

.heading-three {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-transform: capitalize;
  color: #4b9adf;
}
.heading-six {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-transform: capitalize;
  color: #4b9adf;
}

.text-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #2f2f2f;
  line-height: 30px;
  text-align: justify;
}
.disabledLink {
  pointer-events: none;
  opacity: 0.6;
  cursor: default;
}

.bottom-85 {
  bottom: 85px !important;
}

p {
  text-align: justify;
}
a {
  text-decoration: none !important;
}
ul {
  li {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #2f2f2f;
    line-height: 30px;
    text-align: justify;
  }
}
