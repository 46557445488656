.faq-container {
  .accordion {
    .accordion-item {
      background-color: #4a9adf24 !important;
      .accordion-header {
        background-color: #26a9e11a !important;
        margin-top: 20px;
        .accordion-collapse {
          .accordion-body {
            padding: 5px 15px !important;
          }
        }
        .accordion-button[aria-expanded="true"] {
          color: #4a9adf;
          background: #4a9adf1f;
        }
        // a[target="_blank"] {
        //     background-color: yellow;
        //   }
      }
    }
  }
}
