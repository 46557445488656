.service-container {
  .services-image {
    position: relative;
    padding: 30px;
    // background-image: url('../../images/service-fram.png');
    .meta-image {
      position: absolute;
      left: 0;
      padding: 60px;
      top: 0;
      transform: rotate(0.1deg);
    }
  }

  .services-content {
    margin: 0;
    // background: yellow;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    .icon {
      height: 156px;
      width: 150px;
    }
    .heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      color: #4b9adf;
    }

    .body {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #2f2f2f;
      line-height: 30px;
      padding: 0 70px;
      line-height: 40px;
    }
  }
}
.service-detail-container {
  .service-detail-row {
    background: rgb(245 245 245);
    .service-card {
      background-color: white;
      text-align: left;
      // border: 1px solid #4a9adf;
      border-radius: 12px;
      // padding: 5px;

      .card-body {
        padding: 8px !important;
        text-align: left;
        .card-heading {
          padding: 5px 0px 5px 0;
          margin: 0;
          font-size: 20px;
          font-weight: 700;
        }
      }
      img {
        width: 100%;
        height: 310px;
        border-radius: 10px 10px 0 0;
      }
    }
  }

  .text {
    margin: 0;
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #2f2f2f;
    line-height: 30px;
    padding: 0 70px;
    line-height: 40px;
  }

  .service-detail-content-section {
    .top-heading {
      font-size: 20px;
    }
  }

  .servie-detail-support-section {
  }

  .service-detail-game-dev-container {
    .heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      color: #4b9adf;
    }

    .game-service-detail {
      .service-card {
        // background: rgb(245 245 245);
        background-color: #ffffff;
        box-shadow: 8px 2px 15px 15px rgb(0 0 0 / 12%);
        border-radius: 12px;
        .service-card {
          background-color: white;
          text-align: left;
          // border: 1px solid #4a9adf;
          border-radius: 12px;
          // padding: 5px;
          img {
            width: 100% !important;
          }

          .card-body {
            padding: 8px !important;
            text-align: left;
            .card-heading {
              padding: 5px 0px 5px 0;
              margin: 0;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .game-service-detail-innovation-tip {
      .innovation-cards {
        display: flex;
        background: #ffffff;
        box-shadow: 1.04897px 0px 37.763px rgb(0 0 0 / 11%);
        border-radius: 16px;
        padding: 25px;
        margin-top: 25px;
        .innovation-card-img {
          img {
            position: relative;
            top: 0;
            left: 0;
            transform: translate(-10%, 0%);
          }
        }
        .innovation-card-body {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            text-transform: capitalize;
            color: #000000;
          }
          .body {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #2f2f2f;
            line-height: 16px;
          }
        }
      }
    }
  }
  .app-dev-container {
    .sec-one {
      text-align: center;
    }
    .card-section {
      .card-item {
        margin-top: 30px;
        .card-description {
          // font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 38px;
          // text-transform: capitalize;
          color: #393939;
          text-align: justify;
        }
      }
    }
    .section-tow {
      text-align: center;
      background-color: whitesmoke;
      .title {
        padding-top: 30px !important;
        height: 90px;
      }
      .card-img {
        width: 50% !important;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .sec-three {
      .top-app-dev {
        padding-top: 30px;
        .top-app-dev-card {
          background: #ffffff;
          border: 1px solid #4b9adf;
          box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.27);
          border-radius: 35px;
          padding: 50px;
        }
        .smart-dev-pc {
          position: relative;
          .smart-dev-pc-text {
            position: absolute;
            top: 0px;
            left: 0;
            transform: translate(47%, 34%);
            p {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 42px;
              text-transform: capitalize;
              color: #393939;
            }
          }
        }
      }
    }
  }

  .metaverse-container {
    .section-one {
    }
    .section-two {
      .card-img {
        position: relative;
        .card-content {
          position: absolute;
          bottom: 10px;
          left: 0;
          p {
            color: #ffffff !important;
            font-size: 14px;
            padding: 0 40px;
          }
        }
      }
      .cards {
        display: flex;
        background: #ffffff;
        border: 1px solid #27a9e1;
        box-shadow: 1.04897px 0px 37.763px rgba(0, 0, 0, 0.11);
        border-radius: 16px;
        padding: 5px 23px;
        margin-top: 30px;
        height: 148px;
        img {
          height: 70px;
          position: relative;
          transform: translate(-7%, 25%);
        }
        .cards-body {
          margin-left: 25px;
          margin-top: 10px;
          h4 {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
          }
          p {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .bcdev-container {
    .header-section {
      .sect-one-cards {
        margin-top: 20px;
        .card-body {
          position: relative;
          transform: translate(0, 10%);
        }
      }
    }
    .dapp-section {
    }
    .bs-platform {
      .bsc-plat-form-cards {
        position: relative;
        margin-top: 140px !important;
        &:before {
          content: "";
          padding: 40px;
          background-color: #4b9adf;
          position: absolute;
          top: 16px;
          left: 0;
          width: 100%;
          transform: skewY(8deg);
        }
      }
      .bsc-card {
        border: 2px solid #ffffff;
        border-radius: 25px;
        padding: 55px;
        margin-top: 25px;
        height: 520px;
      }
    }
  }
  .devops-container {
    .devops-services {
      .devops-service-cards {
        .card-img {
          height: 450px !important;
          object-fit: fill;
        }
        .card-content {
          position: relative;
          transform: translate(0%, 20%);
        }
      }

      .card-two {
        .img-section {
          position: relative;
          h6 {
            position: absolute;
            top: 30px;
            left: 25px;
          }
        }
      }
    }
    .devsop-sec-three {
      .cards {
        .card-three {
          position: relative;
          .card-content {
            position: absolute;
            top: 20px;
            left: 20px;
            right: 20px;
          }
        }
      }
    }
    .devops-last-sec {
      .last-sec-card {
        background: #ffffff;
        border: 0.905498px solid #ffaa3c;
        box-shadow: 1.08916px 0px 39.2096px rgba(0, 0, 0, 0.11);
        border-radius: 17px;
        padding: 55px;
        margin-top: 30px;
        height: 550px;
      }
    }
    .devops-footer {
      ul li::before {
        content: "\2022";
        color: #4a9adf;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 30px;
      }
    }
  }
}
