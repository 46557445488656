// a{
//     text-decoration: none;
//     :hover{
//         text-decoration: none !important;
//         font-size: 30px;
//     }
// }
.home-container {
  // background-color: red;
  .loader-custom-style {
    position: fixed;
    z-index: 9999;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    // top: 50%;
    // left: 25%;
    background-color: #000000;
    svg {
      width: 500px;
    }
  }
}

.bread-crums-arrow {
  color: #5ccdff;
  font-size: 20px;
  font-weight: 400;
}
