// fontsize
.af-14 {
  font-size: 14px !important;
}
.af-16 {
  font-size: 16px !important;
}
.af-18 {
  font-size: 18px !important;
}
.af-20 {
  font-size: 20px !important;
}

.acard-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  text-transform: capitalize;
  color: #000000;
}

.aw-heading-4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}
.aw-text-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

// colors

.theme-bg {
  background: #4b9adf !important;
}

ul {
  list-style: none;
}
.bread-crums {
  background-color: pink;
}
